import React, { useState, useEffect, useRef } from "react";
import useMobileDetect from 'use-mobile-detect-hook';
import { Reveal, Fade } from 'react-reveal';
import { useTranslation, Trans } from "react-i18next";

import './style.css';

import LogoDark from "./images/logo-dark.svg";
import Ball from "./images/ball.svg";
import BlueFade from "./images/blue-fade.svg";
import PinkFade from "./images/pink-fade.svg";
import Banner from "./images/banner.png";
import Freelancers from "./images/freelancers.svg";
import SmallBusiness from "./images/small-business.svg";
import MediumBusiness from "./images/medium-business.svg";
import BigBusiness from "./images/big-business.svg";
import YourBusinessAccount from './images/your-business-account.png';
import Coins from "./images/coins.svg";
import Payments from "./images/payments.svg";
import Management from "./images/management.svg";
import Graphic from "./images/graphic.svg";
import Document from "./images/document.svg";
import Notifications from "./images/notifications.svg";
import BankBilling from "./images/bank-billing.svg";
import Wallet from "./images/wallet.svg";
import Check from "./images/check.svg";
import ShareAdm from "./images/share-adm.png";
import EyeIcon from "./images/eye.svg";
import CrownIcon from "./images/crown.svg";
import StarIcon from "./images/star.svg";
import CapCard from "./images/capcard.png";
import BusinessApp from "./images/business-app.png";
import Exchange from "./images/exchange.svg";
import Charge from "./images/charge.svg";
import Recharge from "./images/recharge.svg";
import UseCapPay from "./images/use-cappay.svg";
import ArrowLeft from "./images/arrow-left.svg";
import Real from "./images/real.svg";
import Euro from "./images/euro.svg";
import Btc from "./images/btc.svg";
import Eth from "./images/eth.svg";
import Tether from "./images/tether.svg";
import Usd from "./images/usd.svg";
import Gold from "./images/gold.svg";
import Dollar from "./images/dollar.svg";
import Pound from "./images/pound.svg";
import Dash from "./images/dash.svg";
import Litecoin from "./images/litecoin.svg";
import Binance from "./images/binance.svg";
import Dai from "./images/dai.svg";
import PlayStore from "./images/play-store.svg";
import AppStore from "./images/app-store.svg";

function App() {
  const { isMobile } = useMobileDetect();  
  const [offset, setOffset] = useState(0);

  const { t: __, i18n } = useTranslation();

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);

  const signup = () => {
    window.location.href = 'https://biz.capitual.com'
  }

  return (
  <div className="re light">
    <header className="ng reveal-from-bottom">
        <div className="tcontainern">
          <div className="nx">

              <div className="nw">
                <h1 className="sb">
                    <a href="/">
                    <img src={LogoDark} alt="Capitual" />
                    </a>
                </h1>
              </div>

              <input type="checkbox" id="mobileMenu" />
              <label
                id="tp"
                for="mobileMenu"
                className="tp"
                aria-controls="primary-menu"
                aria-expanded="false"
              >
                <span className="iw">Menu</span>{" "}
                <span className="td">
                  <span className="tv" />
                </span>
              </label>

              <nav id="nk" className="nk">
                <div className="n_">
                    <ul className="ix p nq">
                      <li><a href="https://www.capitual.com/" className="ft19">{ __("LandingType.type1") }</a></li>
                      <li><a href="#" className="active ft19">{ __("LandingType.type2") }</a></li>
                    </ul>
                    <ul className="ix nq">
                      <li><a href="https://biz.capitual.com" className="active mr20">Login</a></li>
                      <li><a className="tbuttonn fbuttonl gbuttony" href="javascript:void(0)" onClick={signup}>{ __("SignUpTop.signupbutton") }</a></li>
                    </ul>
                </div>
              </nav>

          </div>
        </div>
    </header>

    <main className="ny">
        <section className="rg nb sm tillustration-section-n">
          <div className="rcontaineri">
              <div className="rd rh pt80 pb60">
                <div className="rv">
                    <h1 className="reveal-from-bottom darker tcenter text-left-mobile" data-reveal-delay={200}>
                      { __("Box1.title") }
                    </h1>

                    <div className="scontainero">
                      <p className="sw mb50 reveal-from-bottom i_ gray ft20 text-left-mobile" data-reveal-delay={400}>
                        { __("Box1.maintext") }
                      </p>
                      <div className="reveal-from-bottom i_" data-reveal-delay={600}>
                        <a className="tbuttonn width2 fbuttonl gbuttony ft18" href="javascript:void(0)" onClick={signup}>{ __("Box1.signupbutton") }</a>
                      </div>
                    </div>
                </div>
                <img src={PinkFade} className="pink-fade" alt="Fade rosa" />
              </div>
          </div>

          <div className="tcontainern p-relative">
            <img src={Ball} alt="Bola" className="banner-ball1" width={29.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
            <Fade bottom>
              <div className="reveal-from-bottom illustration" data-reveal-value="20px" data-reveal-delay={800}>
                <img className="resposinve-img" src={Banner} alt="Banner" />
              </div>
            </Fade>
            <img src={Ball} className="banner-ball2" alt="Bola" width={81.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
            <img src={Ball} className="banner-ball3" alt="Bola" width={47.79} style={{ transform: `translateY(${offset * 0.12}px)`}} />
            <img src={BlueFade} className="blue-fade" alt="Fade azul" />
          </div>
        </section>

        <section className="rw nb">
          <div className="rcontaineri">
              <div className="pricing-inner pt220 pb130 ig">
                <div className="rp">
                  <Fade big when={offset * 0.5}>
                    <h2 className="sw ov darker ft50">{ __("Box2.title") }</h2>
                  </Fade>
                  <Fade big delay={400} when={offset * 0.5}>
                    <p className="sb gray maxw60 ft20 mt35">
                      { __("Box2.maintext") }
                    </p>
                  </Fade>
                </div>
                <div className="tiles-wrap">
                  
                    <Fade big delay={400}>
                      <div className="nv reveal-from-bottom" data-reveal-delay={200}>
                        <div className="nm">
                            <div className="rx">
                              <div className="pricing-item-features mb-40">
                                  <img src={Freelancers} alt={ __("Box2.minibox1.title") } />
                                  <div className="pricing-item-features-title l h ow darker ft19">{ __("Box2.minibox1.title") }</div>
                                  <p className="ft16 gray line-24">{ __("Box2.minibox1.text") }</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    </Fade>
                      
                    <Fade big delay={600}>
                      <div className="nv reveal-from-bottom" data-reveal-delay={500}>
                        <div className="nm">
                            <div className="rx">
                              <div className="pricing-item-features mb-40">
                                  <img src={SmallBusiness} alt={ __("Box2.minibox2.title") } />
                                  <div className="pricing-item-features-title l h ow darker ft19">{ __("Box2.minibox2.title") }</div>
                                  <p className="ft16 gray line-24">{ __("Box2.minibox2.text") }</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    </Fade>  

                    <Fade big delay={800}>
                      <div className="nv reveal-from-bottom" data-reveal-delay={800}>
                        <div className="nm">
                            <div className="rx">
                              <div className="pricing-item-features mb-40">
                                  <img src={MediumBusiness} alt={ __("Box2.minibox3.title") } />
                                  <div className="pricing-item-features-title l h ow darker ft19">{ __("Box2.minibox3.title") }</div>
                                  <p className="ft16 gray line-24">{ __("Box2.minibox3.text") }</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    </Fade>

                    <Fade big delay={1000}>
                      <div className="nv reveal-from-bottom" data-reveal-delay={1100}>
                        <div className="nm">
                            <div className="rx">
                              <div className="pricing-item-features mb-40">
                                  <img src={BigBusiness} alt={ __("Box2.minibox4.title") } />
                                  <div className="pricing-item-features-title l h ow darker ft19">{ __("Box2.minibox4.title") }</div>
                                  <p className="ft16 gray line-24">{ __("Box2.minibox4.text") }</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    </Fade>

                </div>
              </div>
          </div>
        </section>

        <section className="ry nb">
          <div className="rcontaineri p-relative">
            <div className="features-split-inner rh ig">
              <div className="nu nc">
                <div className="split-item mt0 pt0 mobile-reverse">
                  <div className="nl ab reveal-from-left" data-reveal-container=".split-item">
                      <Fade big when={offset * 0.5}>
                        <h3 className="sw ft33 mb40 darker text-left-mobile">{ __("Box3.title") }</h3>
                      </Fade>
                      <Fade big delay={400} when={offset * 0.5}>
                        <p className="sb ft16 gray line-26 text-left-mobile">
                          <strong className="darker">{ __("Box3.texts.subtitle1") }</strong> { __("Box3.texts.text1") }
                          <br /><br />
                          <strong className="darker">{ __("Box3.texts.subtitle2") }</strong> { __("Box3.texts.text2") }
                          <br /><br />
                          <strong className="darker">{ __("Box3.texts.subtitle3") }</strong> { __("Box3.texts.text3") }
                          <br /><br />
                          <strong className="darker">{ __("Box3.texts.subtitle4") }</strong> { __("Box3.texts.text4") }
                        </p>
                      </Fade>
                      <img src={Ball} className="enterprise-ball1" alt="Bola" width={81.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  </div>
                  <img src={Ball} className="enterprise-ball2" alt="Bola" width={40.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <div className="na nf reveal-from-bottom" data-reveal-container=".split-item">
                    <img className="resposinve-img" src={YourBusinessAccount} alt="Sua conta empresarial" />
                    <img src={Ball} className="enterprise-ball3" alt="Bola" width={129.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="rb nb">
          <div className="rcontaineri">
              <div className="features-tiles-inner rh pt80 mb10">
                <div className="tiles-wrap">

                  <Fade big delay={200}>
                    <div className="nv reveal-from-bottom">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Coins} alt={ __("Box4.minibox1.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19">{ __("Box4.minibox1.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox1.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                  <Fade big delay={400}>
                    <div className="nv reveal-from-bottom" data-reveal-delay={200}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Payments} alt={ __("Box4.minibox2.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox2.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox2.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                  <Fade big delay={600}>
                    <div className="nv reveal-from-bottom" data-reveal-delay={400}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Management} alt={ __("Box4.minibox3.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox3.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox3.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                  <Fade big delay={800}>
                    <div className="nv reveal-from-bottom">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Graphic} alt={ __("Box4.minibox4.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox4.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox4.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                  <Fade big delay={1000}>
                    <div className="nv reveal-from-bottom" data-reveal-delay={200} style={{zIndex: 9999999}}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Document} alt={ __("Box4.minibox5.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox5.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox5.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                  <Fade big delay={1200}>
                    <div className="nv reveal-from-bottom" data-reveal-delay={400}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Notifications} alt={ __("Box4.minibox6.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox6.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox6.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                  <Fade big delay={1400}>
                    <div className="nv reveal-from-bottom">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={BankBilling} alt={ __("Box4.minibox7.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox7.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox7.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={1600}>
                    <div className="nv reveal-from-bottom">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic">
                              <img src={Wallet} alt={ __("Box4.minibox8.title") } />
                            </div>
                          </div>
                          <div className="features-tiles-item-content ht220 mt30">
                            <h4 className="sw os darker ft19 line-24">{ __("Box4.minibox8.title") }</h4>
                            <p className="sb c gray ft16 line-24">
                              { __("Box4.minibox8.text") } 
                            </p>
                          </div>
                      </div>
                    </div>
                    </Fade>

                    <img src={BlueFade} className="blue-fade2" alt="Fade azul" style={{zIndex: 0}} />
                </div>
              </div>
          </div>
        </section>

        <section className="ry nb">
          <div className="rcontaineri">
            <div className="features-split-inner rh ig mb0">
              <div className="nu nc">
                <div className="split-item">
                  <div className="na nf reveal-from-bottom" data-reveal-container=".split-item">
                    <img className="resposinve-img" src={ShareAdm} alt={ __("Box5.title") } />
                  </div>
                  <img src={Ball} className="share-ball1" alt="Bola" width={90.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <img src={Ball} className="share-ball2" alt="Bola" width={61.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <img src={Ball} className="share-ball3" alt="Bola" width={21.79} style={{ transform: `translateY(${offset * 0.1}px)`}} />
                  <div className="nl ab reveal-from-right" data-reveal-container=".split-item">
                    <Fade big when={offset * 0.5}>
                      <h3 className="sw ft33 mb30 darker text-left-mobile">{ __("Box5.title") }</h3>
                    </Fade>
                    <Fade big delay={200} when={offset * 0.5}>
                      <p className="sb ft16 gray mb40 text-left-mobile">
                        { __("Box5.texts.maintext") }
                      </p>
                    </Fade>

                    <Fade big delay={400} when={offset * 0.5}>
                      <div className="check-item mb25">
                        <img src={CrownIcon} alt="check" /> 
                        <p className="sb ft16 gray ml20 line-26 -mt5 text-left-mobile">
                          { __("Box5.texts.text1") } 
                        </p>
                      </div>
                      <div className="check-item mb25">
                        <img src={StarIcon} alt="check" /> 
                        <p className="sb ft16 gray ml20 line-26 -mt5 text-left-mobile">
                          { __("Box5.texts.text2") } 
                        </p>
                      </div>
                      <div className="check-item mb25">
                        <img src={EyeIcon} alt="check" /> 
                        <p className="sb ft16 gray ml20 line-26 -mt5 text-left-mobile">
                          { __("Box5.texts.text3") }
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ry nb">
          <div className="rcontaineri">
            <div className="features-split-inner rh ig">
              <div className="nu nc">
                <div className="split-item mt0 pt0 mobile-reverse">
                  <div className="nl ab reveal-from-left" data-reveal-container=".split-item">
                    <div className="soon" style={i18n.language === 'en-US' ? { width: "30%"} : { width: "20%"}}>
                      <p className="darker ft14 mb0 line-26 ft-bold">{ __("Box6.soon") }</p>
                    </div>
                      <Fade big when={offset * 0.5}>
                        <h3 className="sw ft33 mb35 darker maxw80 text-left-mobile">{ __("Box6.title") }</h3>
                      </Fade>
                      <Fade big delay={400} when={offset * 0.5}>
                        <p className="sb ft16 gray mb40 line-26 text-left-mobile">
                          { __("Box6.texts.maintext") }
                        </p>
                      </Fade>

                      <Fade big delay={600} when={offset * 0.5}>
                        <div className="check-item mb25 line-26">
                          <img src={Check} alt="check" /> 
                          <p className="sb ft16 gray ml20 -mt10 text-left-mobile">
                            { __("Box6.texts.text1") } 
                          </p>
                        </div>
                        <div className="check-item mb25 line-26">
                          <img src={Check} alt="check" /> 
                          <p className="sb ft16 gray ml20 -mt10 text-left-mobile">
                            { __("Box6.texts.text2") }
                          </p>
                        </div>
                        <div className="check-item mb25 line-26">
                          <img src={Check} alt="check" /> 
                          <p className="sb ft16 gray ml20 -mt10 text-left-mobile">
                            { __("Box6.texts.text3") }
                          </p>
                        </div>
                      </Fade>
                  </div>

                  <div className="na nf reveal-from-bottom" data-reveal-container=".split-item">
                    <img className="resposinve-img" src={CapCard} alt="CapCard" />
                  </div>
                  <img src={Ball} className="cardpay-ball1" alt="Bola" width={105.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <img src={Ball} className="cardpay-ball2" alt="Bola" width={53.79} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <img src={Ball} className="cardpay-ball3" alt="Bola" width={22} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                </div>
              </div>
              <img src={PinkFade} className="pink-fade2" alt="Fade rosa" />
            </div>
          </div>
        </section>

        <section className="ry nb">
          <div className="rcontaineri">
            <div className="features-split-inner rh ig pt0 pb0">
              <div className="nu nc">
                <div className="split-item">
                  <img src={Ball} className="business-ball2" alt="Bola" width={20} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <div className="na nf reveal-from-bottom" data-reveal-container=".split-item">
                    <img className="resposinve-img" src={BusinessApp} alt="Aplicativo individual para o CapBusiness" />
                  </div>
                  <img src={Ball} className="business-ball1" alt="Bola" width={87} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <img src={Ball} className="business-ball3" alt="Bola" width={50} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                  <div className="nl ab reveal-from-right" data-reveal-container=".split-item">
                    <div className="soon" style={i18n.language === 'en-US' ? { width: "30%"} : { width: "20%"}}>
                      <p className="darker ft14 mb0 line-26 ft-bold">{ __("Box7.soon") }</p>
                    </div>
                    <Fade big when={offset * 0.5}>
                      <h3 className="sw ft33 mb40 darker maxw70 text-left-mobile">{ __("Box7.title") }</h3>
                    </Fade>
                    <Fade big delay={400} when={offset * 0.5}>
                      <p className="sb ft16 gray mb40 text-left-mobile">
                        { __("Box7.texts.maintext") }
                      </p>
                    </Fade>

                    <Fade big delay={600} when={offset * 0.5}>
                      <div className="check-item mb25">
                        <img src={Check} alt="check" /> 
                        <p className="sb ft16 gray ml20 -mt10 text-left-mobile">
                          { __("Box7.texts.text1") } 
                        </p>
                      </div>
                      <div className="check-item mb25">
                        <img src={Check} alt="check" /> 
                        <p className="sb ft16 gray ml20 -mt10 text-left-mobile">
                          { __("Box7.texts.text2") }   
                        </p>
                      </div>
                      <div className="check-item mb25">
                        <img src={Check} alt="check" /> 
                        <p className="sb ft16 gray ml20 -mt10 text-left-mobile">
                          { __("Box7.texts.text3") } 
                        </p>
                      </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="rw nb">
          <div className="rcontaineri">
              <div className="pricing-inner rh ig mb100 pt-100-mobile pb-0-mobile">
                <div className="rp">
                  <Fade big when={offset * 0.5}>
                    <h2 className="sw ov darker txleft ft33">{ __("Box8.title") }</h2>
                  </Fade>
                  <Fade big delay={400} when={offset * 0.5}>
                  <p className="sb gray maxw50 ft16 mt25 line-26">
                    { __("Box8.maintext") }
                  </p>
                  </Fade>
                </div>
                <div className="tiles-wrap">

                <Fade big delay={400}>
                  <div className="nv three-items reveal-from-bottom">
                    <div className="nm bg-transparent">
                        <div className="transparent-item">
                          <img src={Exchange} alt={ __("Box8.boxes.minibox1.title") } />
                          <div className="features-tiles-item-content ml20 -mt10">
                            <h4 className="sw os darker ft19">{ __("Box8.boxes.minibox1.title") }</h4>
                            <p className="sb c gray ft16 line-26">
                              { __("Box8.boxes.minibox1.text") } 
                            </p>
                          </div>
                        </div>
                    </div>
                  </div>
                </Fade>

                  <Fade big delay={600}>
                    <div className="nv three-items reveal-from-bottom">
                      <div className="nm bg-transparent">
                          <div className="transparent-item">
                            <img src={Charge} alt={ __("Box8.boxes.minibox2.title") } />
                            <div className="features-tiles-item-content ml20 -mt10">
                              <h4 className="sw os darker ft19">{ __("Box8.boxes.minibox2.title") }</h4>
                              <p className="sb c gray ft16 line-26">
                                { __("Box8.boxes.minibox2.text") }  
                              </p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={800}>
                    <div className="nv three-items reveal-from-bottom">
                      <div className="nm bg-transparent">
                          <div className="transparent-item">
                            <img src={Recharge} alt={ __("Box8.boxes.minibox3.title") } />
                            <div className="features-tiles-item-content ml20 -mt10">
                              <h4 className="sw os darker ft19">{ __("Box8.boxes.minibox3.title") }</h4>
                              <p className="sb c gray ft16 line-26">
                                  { __("Box8.boxes.minibox3.text") }  
                              </p>
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>
                    
                </div>
              </div>
          </div>
        </section>
        
        <section className="ir nb ab reveal-from-bottom pt40">
          <div className="rcontaineri">
              <div className="bg-grad-blue rh is">
                <img src={Ball} className="cappay-ball" alt="Bola" width={69.91} style={{ transform: `translateY(${offset * 0.12}px)`}} />
                <div className="ii">
                  <Fade big when={offset * 0.5}>
                    <h1 className="sb text-left-mobile white s ft44">{ __("Box9CapPay.title1") }  {!isMobile() && <br />} { __("Box9CapPay.title2") }</h1>
                  </Fade>
                  <Fade big delay={400} when={offset * 0.5}>
                    <p className="ft19 maxw50 mt20 text-left-mobile">
                      { __("Box9CapPay.maintext") }
                    </p>
                    <a href="https://cappay.capitual.com" className="ft19 link-inline">
                      { __("Box9CapPay.button") }
                      <img src={ArrowLeft} alt="Setinha" className="ml20 -mb5" />
                    </a>
                  </Fade>
                </div>
                <div className="cta-action">
                  <img src={UseCapPay} alt={ __("Box9CapPay.title1") + ". " + __("Box9CapPay.title2") } />
                </div>
            </div>
          </div>
        </section>

        <section className="rb nb">
          <div className="rcontaineri">
              <div className="features-tiles-inner rh pb0 pt-100-mobile">
                <Fade big when={offset * 0.5}>
                  <h3 className="sb ft23 mb90 i_ gray line-31">{ __("Box10.title1") } {!isMobile() && <br />} { __("Box10.title2") }</h3>
                </Fade>
                <div className="tiles-wrap">

                  <Fade big delay={200}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Real} alt="Real" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={400}>
                    <div className="nv reveal-from-bottom center-flex" data-reveal-delay={200}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Euro} alt="Euro" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={600}>
                    <div className="nv reveal-from-bottom center-flex" data-reveal-delay={400}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Btc} alt="Bitcoin" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={800}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Eth} alt="Etherium" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={1000}>
                    <div className="nv reveal-from-bottom center-flex" data-reveal-delay={200}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Tether} alt="Tether" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={1200}>
                    <div className="nv reveal-from-bottom center-flex" data-reveal-delay={400}>
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Usd} alt="Moedas USD" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={1400}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Gold} alt="Ouro" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={1600}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Dollar} alt="Dólar" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={1800}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Pound} alt="Libra Esterlina" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={2000}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Dash} alt="Dash" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={2200}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Litecoin} alt="Litecoin" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={2400}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Binance} alt="Binance USD" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade big delay={2600}>
                    <div className="nv reveal-from-bottom center-flex">
                      <div className="nm">
                          <div className="features-tiles-item-header">
                            <div className="ic ic2">
                              <img src={Dai} alt="Dai" />
                            </div>
                          </div>
                      </div>
                    </div>
                  </Fade>

                </div>
              </div>
          </div>
        </section>

        <section className="rg nb sm tillustration-section-n mb60">
          <div className="rcontaineri">
              <div className="rd rh pt100">
                <div className="rv center-child">
                  <Fade big delay={400} when={offset * 0.5}>
                    <h1 className="reveal-from-bottom darker i_ mb15 maxw70" data-reveal-delay={200}>
                      { __("Box11.title") }
                    </h1>
                  </Fade>

                    <div className="scontainero">
                      <p className="sw mb40 reveal-from-bottom i_ gray ft16" data-reveal-delay={400}>
                        { __("Box11.maintext") }
                      </p>
                      <div className="reveal-from-bottom i_ buttons-container" data-reveal-delay={600}>
                        <a className="tbuttonn width2 fbuttonl gbuttony ft18" href="javascript:void(0)" onClick={signup}>{ __("Box11.signupbutton") }</a>
                        <a className="tbuttonn outline width2 fbuttonl gbuttony ft18" href="https://exchange.capitual.com">{ __("Box11.knowratesbutton") }</a>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </section>

    </main>
    <footer className="rt">
        <div className="tcontainern">
          <div className="rn ig">
            <div className="rr p">
              <div className="rs">
                <div className="ro more-padding mfc" style={{boxSizing: 'content-box'}}>
                  <div className="nw ov">
                    <img
                      src={LogoDark}
                      alt="Capitual"
                    />
                    <p className="ft17 gray mt33 line-26">
                      { __("FooterLeft.maintext") }
                    </p>
                  </div>
                  <div className="footer-copyright">
                    <div className="ra">
                      <ul className="ix">
                        <li className="sd2">
                          <a
                            href="https://twitter.com/capitual"
                            target="_blank"
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Twitter</title>
                              <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
                            </svg>
                          </a>
                        </li>
                        <li className="sd2">
                          <a href="https://instagr.am/capitual" target="_blank">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Instagram</title>
                              <g>
                                <circle cx="12.145" cy="3.892" r={1} />
                                <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                                <path d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
                              </g>
                            </svg>
                          </a>
                        </li>
                        <li className="sd2">
                          <a href="https://fb.com/capitual" target="_blank">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Facebook</title>
                              <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
                            </svg>
                          </a>
                        </li>
                        <li className="sd2">
                          <a href="https://medium.com/capitual" target="_blank">
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>Medium</title>
                              <path d="M14.723,3.155l1.277,-1.221l0,-0.267l-4.423,0l-3.152,7.845l-3.586,-7.845l-4.637,0l0,0.267l1.491,1.795c0.146,0.133 0.222,0.327 0.202,0.522l0,7.056c0.046,0.254 -0.036,0.515 -0.215,0.7l-1.68,2.036l0,0.264l4.763,0l0,-0.268l-1.68,-2.032c-0.182,-0.186 -0.268,-0.442 -0.231,-0.7l0,-6.103l4.181,9.106l0.486,0l3.596,-9.106l0,7.254c0,0.191 0,0.231 -0.126,0.356l-1.293,1.251l0,0.268l6.275,0l0,-0.267l-1.247,-1.221c-0.109,-0.082 -0.166,-0.221 -0.143,-0.356l0,-8.978c-0.023,-0.135 0.033,-0.274 0.142,-0.356Z" />
                            </svg>
                          </a>
                        </li>
                        <li className="sd2">
                          <a
                            href="https://www.linkedin.com/company/capitual/"
                            target="_blank"
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 16 16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>LinkedIn</title>
                              <path d="M8.814,5.141c0.143,0 0.258,0.116 0.258,0.259l0,0.671c0.579,-0.631 1.532,-1.187 2.822,-1.187c3.618,0 4.106,2.621 4.106,5.185l0,5.672c0,0.143 -0.116,0.259 -0.258,0.259l-3.206,0c-0.143,0 -0.258,-0.116 -0.258,-0.259l0,-5.028c0,-1.407 -0.104,-2.484 -1.41,-2.484c-1.2,0 -1.667,0.671 -1.667,2.396l0,5.116c0,0.143 -0.115,0.259 -0.258,0.259l-3.205,0c-0.142,0 -0.258,-0.116 -0.258,-0.259l0,-10.341c0,-0.143 0.116,-0.259 0.258,-0.259l3.076,0Zm-5.09,0c0.143,0 0.258,0.116 0.258,0.259l0,10.341c0,0.143 -0.115,0.259 -0.258,0.259l-3.212,0c-0.142,0 -0.258,-0.116 -0.258,-0.259l0,-10.341c0,-0.143 0.116,-0.259 0.258,-0.259l3.212,0Zm-1.604,-5.141c-1.169,0 -2.12,0.952 -2.12,2.122c0,1.17 0.951,2.123 2.12,2.123c1.167,0 2.117,-0.953 2.117,-2.123c0,-1.17 -0.95,-2.122 -2.117,-2.122Z" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="ra">
                      <ul className="ix">
                        <li className="sd2">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.capitual"
                            target="_blank"
                            className="p2"
                          >
                            <img src={PlayStore} alt="play store" />
                          </a>
                        </li>
                        <li className="sd2">
                          <a
                            href="https://apps.apple.com/app/id1458793819"
                            target="_blank"
                          >
                            <img src={AppStore} alt="app store" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="ro">
                  <div className="ru ft19 darker ft-bold">
                    { __("FooterRight.miniboxes.minibox1.title") }
                  </div>
                  <ul className="ix sk">
                    <li className="mt25 mb18">
                      <a className="ft16" href="https://my.capitual.com/about">
                        { __("FooterRight.miniboxes.minibox1.button1") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://blog.capitual.com">
                        { __("FooterRight.miniboxes.minibox1.button2") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://exchange.capitual.com">
                        { __("FooterRight.miniboxes.minibox1.button3") }
                      </a>
                    </li>
                  </ul>

                  <div className="ru ft19 darker ft-bold">
                    { __("FooterRight.miniboxes.minibox2.title") }
                  </div>
                  <ul className="ix sk">
                    <li className="mt25 mb18">
                      <a
                        className="ft16 darkBlue"
                        href={"mailto:"+__('email')}
                      >
                        { __('email') }
                      </a>
                    </li>
                    <li className="mb18">
                      <a
                        className="ft16 darkBlue"
                        href={"tel:"+__('phone').replace(/ /g, "")}
                      >
                        { __('phone') }
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ro">
                  <div className="ru ft19 darker ft-bold">
                    { __("FooterRight.miniboxes.minibox3.title") }
                  </div>
                  <ul className="ix sk">
                    <li className="mt25 mb18">
                      <a className="ft16" href="https://www.capitual.com" onClick={() => {}}>
                        { __("FooterRight.miniboxes.minibox3.button1") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://business.capitual.com" onClick={() => {}}>
                        { __("FooterRight.miniboxes.minibox3.button2") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a
                        className="ft16"
                        href="https://cappay.capitual.com"
                      >
                        { __("FooterRight.miniboxes.minibox3.button3") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://otc.capitual.com">
                        { __("FooterRight.miniboxes.minibox3.button4") }
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ro">
                  <div className="ru ft19 darker ft-bold">{ __("FooterRight.miniboxes.minibox4.title") }</div>
                  <ul className="ix sk">
                    <li className="mt25 mb18">
                      <a className="ft16" href="https://status.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button1") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://dev.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button2") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://opensource.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button3") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://press.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button4") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://building.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button5") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://security.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button6") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://help.capitual.com">
                        { __("FooterRight.miniboxes.minibox4.button7") }
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ro">
                  <div className="ru ft19 darker ft-bold">{ __("FooterRight.miniboxes.minibox5.title") }</div>
                  <ul className="ix sk">
                    <li className="mt25 mb18">
                      <a
                        className="ft16"
                        href="https://my.capitual.com/legal#tos"
                      >
                        { __("FooterRight.miniboxes.minibox5.button1") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a
                        className="ft16"
                        href="https://my.capitual.com/legal#pp"
                      >
                        { __("FooterRight.miniboxes.minibox5.button2") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://go.capitu.al/pb">
                        { __("FooterRight.miniboxes.minibox5.button3") }
                      </a>
                    </li>
                    <li className="mb18">
                      <a className="ft16" href="https://go.capitu.al/kycaml">
                        { __("FooterRight.miniboxes.minibox5.button4") }
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tcontainern">
              <div className="field has-text-centered lang-selection w100">
                <div className="control is-expanded footer-items">
                    {/* <select onChange={e => i18n.changeLanguage(e.target.value, getBlog)}>
                      { Object.keys(i18n.options.resources).map(item => (
                        <option value={item} selected={item === i18n.language}>{i18n.options.resources[item].translation.language}</option>
                      )") }
                    </select> */}
                    <div className="false-select">
                      { Object.keys(i18n.options.resources).map(item => (
                        <a href="#" onClick={() => i18n.changeLanguage(item)} className={item === i18n.language && "active"}>{i18n.options.resources[item].translation.language}</a>
                      )) }
                    </div>
                  <p className="gray mb0">Capitual &copy; {new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  </div>
  );
}

export default App;